import { Container, styled } from '@mui/material';
import { FC, ReactElement } from 'react';
import defaultBG from '../media/main_bg.png';
import { GeneralLogo } from '../components/GeneralLogo';

const View = styled(Container)`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.5s ease-in;
  overflow: hidden;
  position: relative;
  padding: 0 28px;
`;

export const ViewContainer: FC<{
  children: ReactElement;
  bg?: string;
  keyAnim: string;
}> = ({ children, bg = defaultBG }) => {
  return (
    <View
      sx={{
        backgroundImage: `url(${bg})`,
      }}
    >
      {children}
      <GeneralLogo />
    </View>
  );
};
