import styled from '@emotion/styled';
import { Box } from '@mui/material';

const anim = {};

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 333px;
  position: relative;
`;

export const PreStartContainer = styled(Container)`
  background: #f7f7f6;
  border-radius: 30px;
  padding: 30px 15px 18px 15px;
  position: relative;
`;

export const CardContainer = styled(Box)`
  width: 100%;
  max-width: 333px;
  max-height: 374px;
  height: calc(var(--vh, 1vh) * 43);
  z-index: 10;
  filter: url(#liquid);
  //transition: background-color 0.5s ease-in;
`;

export const ImageContainer = styled(Box)`
  max-height: 234px;
  height: calc(var(--vh, 1vh) * 27);
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  //transition: background-color 0.5s ease-in;
`;

export const TextContainer = styled.div`
  height: 60%;
  min-width: 271px;
  padding: 0 31px;
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.div`
  max-height: 220px;
  height: calc(var(--vh, 1vh) * 25.8);
  aspect-ratio: 1/1;
  background: #f7f7f6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: solid 1px #636363;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Filter = () => (
  <svg>
    <filter id="liquid">
      <feGaussianBlur id="blur1" in={'SourceGraphic'} stdDeviation={10} />
      <feColorMatrix
        values="
              1 0 0 0 0
              0 1 0 0 0
              0 0 1 0 0
              0 0 0 50 -10
          "
      />
    </filter>
  </svg>
);

export enum CardState {
  PRESTART = '',
  WAITING = 'wait',
  INTERACTION = 'interact',
  FINISH = 'fin',
}

export const countDownFn = (value: number) => (prev: number) => prev - value;
