import { FC } from 'react';
import {
  Box,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PredefinedButton } from './PredefinedButton';
import nebula from '../media/nebula37.mp4';
import digitalTime from '../media/digitalTime.mp4';
import northernLight from '../media/nortenLight.mp4';
import sakura from '../media/sakura.mp4';
import cyber from '../media/cyber.mp4';
import { useConnection } from '../providers/useConnection';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const BackBtn = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
`;

export const ListOfPredefined: FC<{ close: () => void }> = ({ close }) => {
  const navigate = useNavigate();
  const { addAnimation } = useConnection();
  const setAnimation = (name: string) => async () => {
    const delay = await addAnimation({ predefined: true, name });
    delay.predefined = true;
    navigate('/run', {
      state: delay,
    });
  };
  return (
    <>
      <Box mt={5} mb={1.5} style={{ position: 'relative' }}>
        <BackBtn onClick={close}>
          <ArrowBackIosNewIcon />
        </BackBtn>
        <Typography
          textAlign={'center'}
          textTransform={'uppercase'}
          variant={'h5'}
          color={'primaryColor.main'}
        >
          Digital Stories
        </Typography>
      </Box>
      <DialogContent
        sx={{
          paddingTop: 0,
        }}
      >
        <Grid spacing={1.5} container justifyContent={'center'}>
          <Grid pt={5} item xs={12}>
            <PredefinedButton
              name={'Nebula 37х'}
              description={
                'The stars are shining.They inspire. If you are open to innovation.'
              }
              preview={nebula}
              onClick={setAnimation}
            />
          </Grid>
          <Grid item xs={12}>
            <PredefinedButton
              name={'Digital time wave'}
              description={
                'A mysterious glow enveloped the sky. Happy is the one who sees the beauty around him.'
              }
              preview={digitalTime}
              onClick={setAnimation}
            />
          </Grid>
          <Grid item xs={12}>
            <PredefinedButton
              name={'Northern Lights'}
              description={
                "Midnight's canvas glows. Aurora's brushstrokes ignite. Nature's art in flight."
              }
              preview={northernLight}
              onClick={setAnimation}
            />
          </Grid>
          <Grid item xs={12}>
            <PredefinedButton
              name={'Digital Sakura Blossom'}
              description={
                'Rushing is poison. But the future is only created without wasting time.'
              }
              preview={sakura}
              onClick={setAnimation}
            />
          </Grid>
          <Grid item xs={12}>
            <PredefinedButton
              name={'Cyber whirlwind'}
              description={
                'Cyber wind whispers. Code in motion, pixels dance. Art in digital.'
              }
              preview={cyber}
              onClick={setAnimation}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
