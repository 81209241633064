import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
function isRunningStandalone() {
  return window.matchMedia('(display-mode: browser)').matches;
}
//standalone mode
const FullScreenProvider = ({ targetEl = document.body, children }: any) => {
  const [open, setDial] = useState(isRunningStandalone());
  const setFullScreenMode = () => {
    targetEl
      .requestFullscreen({ navigationUI: 'hide' })
      .then(() => setDial(false))
      .catch((e: any) => {
        setDial(false);
        console.error(e);
      });
  };
  useEffect(() => {
    if (!document.fullscreenEnabled) {
      setDial(false);
    }
  }, []);
  return (
    <>
      {!open && children}
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>Open app in full screen mode?</DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button onClick={() => setDial(false)}>NO</Button>
          <Button onClick={setFullScreenMode}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FullScreenProvider;
