import { FC, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Hue from '@uiw/react-color-hue';
import { hsvaToRgba, rgbaToHsva } from '@uiw/color-convert';
import { TaskDTO } from '../../../back/src/three_live/queue/types/three_of_life_shared';
import { Effects } from '../types/three_of_life_shared';
import { Preview } from './Preview';
import { EffectsSelector } from './EffectsSelector';
import { SpeedSelector } from './SpeedSelector';
import { BlurrySelector } from './BlurrySelector';
import { useConnection } from '../providers/useConnection';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion, Variants } from 'framer-motion';
const steps: string[] = ['CHOOSE COLOUR', 'CHOOSE EFFECT', 'CHOOSE TYPE'];

type HSVA = {
  h: number;
  s: number;
  v: number;
  a: number;
};

const variant: Variants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.3,
    },
  },
};

export const Creator: FC<{ close: () => void; state: any }> = ({
  close,
  state,
}) => {
  const navigate = useNavigate();
  const [creatorSteps, setStep] = useState((state?.step as number) || 0);
  const [color, setColor] = useState<HSVA>(
    state?.animation?.color
      ? rgbaToHsva({
          r: state?.animation?.color[0],
          g: state?.animation?.color[1],
          b: state?.animation?.color[2],
          a: 1,
        })
      : {
          h: 0,
          s: 100,
          v: 100,
          a: 1,
        },
  );
  const [effect, setEffect] = useState<Effects>(
    (state?.animation?.effect as Effects) || Effects.WHIRLWIND,
  );
  const [speed] = useState<number>(100);
  const [blurry, setBlurry] = useState<boolean>(
    (state?.animation?.blur as boolean) || false,
  );

  const incrementStep = () => {
    setStep((step) => ++step);
  };
  const decrementStep = () => {
    setStep((step) => --step);
  };

  const setAnimation = (animation: any) => async () => {
    navigate('/run', {
      state: {
        delay: null,
        animation: {
          ...animation,
          color: [animation.color.r, animation.color.g, animation.color.b],
          predefined: false,
          name: null,
        },
      },
    });
  };
  return (
    <>
      <Stack
        mt={'calc(var(--vh, 1vh) * 5.285)'}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <IconButton onClick={!creatorSteps ? close : decrementStep}>
          <ArrowBackIosNewIcon />
        </IconButton>
      </Stack>
      <Box textAlign={'center'}>
        <Typography color={'white'} variant={'h2'} textTransform={'uppercase'}>
          {`Step ${creatorSteps + 1}`}
        </Typography>
        <Typography
          pt={'calc(var(--vh, 1vh) * 2.9)'}
          fontWeight={700}
          variant={'body1'}
          textTransform={'uppercase'}
          color={'primaryColor.main'}
        >
          {steps[creatorSteps]}
        </Typography>
      </Box>
      <Preview
        blur={blurry}
        effect={effect}
        speed={speed}
        color={hsvaToRgba(color)}
      />
      <Box
        mt={'calc(var(--vh, 1vh) * 7.2)'}
        height={'calc(var(--vh, 1vh) * 9.3)'}
      >
        <AnimatePresence initial={false}>
          <motion.div
            key={creatorSteps}
            variants={variant}
            initial={'hidden'}
            animate={'enter'}
            exit={'exit'}
          >
            {creatorSteps === 0 && (
              <Hue
                hue={color.h}
                onChange={(color) => {
                  setColor((prev) => ({ ...prev, ...color }));
                }}
              />
            )}
            {creatorSteps === 1 && <EffectsSelector setEffect={setEffect} />}
            {creatorSteps === 2 && (
              <BlurrySelector blurry={blurry} setBlurry={setBlurry} />
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Box textAlign={'center'} mt={'calc(var(--vh, 1vh) * 7)'}>
        <Button
          variant={'outlined'}
          onClick={
            creatorSteps < 2
              ? incrementStep
              : setAnimation({
                  blur: blurry,
                  effect: effect,
                  speed: speed,
                  color: hsvaToRgba(color),
                })
          }
        >
          Next
        </Button>
      </Box>
    </>
  );
};
