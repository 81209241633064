import './App.css';
import { useLocation, useRoutes } from 'react-router-dom';
import { router } from './router';
import { AnimatePresence, motion, Variants } from 'framer-motion';
const forwardVariant: Variants = {
  hidden: {
    x: '100vw',
    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  visible: {
    x: 0,
    zIndex: 100,
    transition: {
      type: 'Tween',
      stiffness: 100,
      duration: 0.8,
    },
  },
  exit: {
    position: 'absolute',
    top: 0,
    left: 0,
    x: '-50vw',
    zIndex: 50,
    transition: {
      type: 'Tween',
      stiffness: 100,
      delay: 0.4,
      duration: 0.4,
    },
  },
};
const backVariant: Variants = {
  hidden: {
    x: '-100vh',
    zIndex: 100,
  },
  visible: {
    x: 0,
    zIndex: 100,
    transition: {
      type: 'Tween',
      stiffness: 100,
      duration: 0.8,
    },
  },
  exit: {
    x: '50vw',
    zIndex: 50,
    pointerEvents: 'none',
    transition: {
      type: 'Tween',
      stiffness: 100,
      delay: 0.4,
      duration: 0.4,
    },
  },
};
function App() {
  const routes = useRoutes(router);
  const location = useLocation();
  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={location.key}
        initial={'hidden'}
        animate={'visible'}
        exit={'exit'}
        variants={forwardVariant}
      >
        {routes}
      </motion.div>
    </AnimatePresence>
  );
}

export default App;
