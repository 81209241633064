import infoIcon from '../media/info.svg';
import { IconButton } from '@mui/material';
import { FC } from 'react';

export const InfoButton: FC<{ visibility?: string; onClick?: () => void }> = ({
  visibility = '',
  onClick,
}) => {
  return (
    <IconButton onClick={onClick} sx={{ visibility }}>
      <img
        src={infoIcon}
        style={{ height: 'calc(var(--vh, 1vh) * 4.6)', maxHeight: 40 }}
        alt={'Information'}
      />
    </IconButton>
  );
};
