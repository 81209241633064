enum Effects {
  WHIRLWIND,
  SHINE,
  WAVE,
  GEOMETRY,
}

enum Speed {
  SLOW,
  MID,
  FAST,
}

export { Effects, Speed };
