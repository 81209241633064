import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Effects } from '../types/three_of_life_shared';
import Wind from '../media/three/preview/Whirl.mp4';
import Geo from '../media/three/preview/Geo.mp4';
import Wave from '../media/three/preview/Wave.mp4';
import Sine from '../media/three/preview/Shine.mp4';
import styled from '@emotion/styled';

const VideoBtn = styled(Button)`
  width: 76px;
  height: 85px;
  display: flex;
  flex-direction: column;
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 8px;
  }
  & p {
    font-size: 12px;
    text-transform: initial;
    text-decoration: none;
  }
`;

export const EffectsSelector: FC<{ setEffect: (effect: Effects) => void }> = ({
  setEffect,
}) => {
  const selectEffect = (effect: Effects) => () => setEffect(effect);
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
      <VideoBtn onClick={selectEffect(Effects.WHIRLWIND)}>
        <video
          src={Wind}
          autoPlay
          loop
          muted
          playsInline
          preload={'auto'}
          crossOrigin={'anonymous'}
        />
        <Typography>Whirlwind</Typography>
      </VideoBtn>
      <VideoBtn onClick={selectEffect(Effects.SHINE)}>
        <video
          src={Sine}
          autoPlay
          loop
          muted
          playsInline
          preload={'auto'}
          crossOrigin={'anonymous'}
        />
        <Typography>Shine</Typography>
      </VideoBtn>
      <VideoBtn onClick={selectEffect(Effects.WAVE)}>
        <video
          src={Wave}
          autoPlay
          loop
          muted
          playsInline
          preload={'auto'}
          crossOrigin={'anonymous'}
        />
        <Typography>Wave</Typography>
      </VideoBtn>
      <VideoBtn onClick={selectEffect(Effects.GEOMETRY)}>
        <video
          src={Geo}
          autoPlay
          loop
          muted
          playsInline
          preload={'auto'}
          crossOrigin={'anonymous'}
        />
        <Typography>Geometry</Typography>
      </VideoBtn>
    </Stack>
  );
};
