import {
  FC,
  forwardRef,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Box, Button, Dialog, Slide, Stack, styled } from '@mui/material';
import { Logo } from '../components/Logo';
import { InfoButton } from '../components/InfoButton';
import { TransitionProps } from '@mui/material/transitions';
import { Creator } from '../components/Creator';
import { ListOfPredefined } from '../components/ListOfPredefined';
import { useLocation, useNavigate } from 'react-router-dom';

export const LargeButton = styled(Button)`
  padding: 1.4375em 3.1875em;
`;

const ButtonsGroup: FC<{ openCreator: () => void; openList: () => void }> = ({
  openCreator,
  openList,
}) => (
  <Box
    sx={{
      position: 'relative',
      top: '24.06%',
    }}
  >
    <Box mb={3.375}>
      <LargeButton variant={'outlined'} fullWidth onClick={openList}>
        Discover & Enjoy
      </LargeButton>
    </Box>
    <Box>
      <LargeButton variant={'outlined'} fullWidth onClick={openCreator}>
        Create & Interact
      </LargeButton>
    </Box>
  </Box>
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
) {
  return (
    <Slide timeout={{ enter: 5000, exit: 5000 }} direction="up" {...props} />
  );
});

export const AnimationCreatorPage: FC = () => {
  const { state } = useLocation();
  const stateRef = useRef(state);
  const [isCreatorOpen, creatorOpen] = useState(!!stateRef.current?.animation);
  const [isListOpen, openList] = useState(false);

  const closeCreator = useCallback(() => {
    stateRef.current = {};
    creatorOpen(false);
  }, [creatorOpen]);

  const closeList = useCallback(() => {
    openList(false);
  }, [openList]);

  return (
    <>
      <Stack
        mt={'calc(var(--vh, 1vh) * 5.285)'}
        justifyContent={'space-between'}
        direction={'row'}
        alignItems={'center'}
      >
        <Logo />
        <InfoButton visibility={'hidden'} />
      </Stack>
      <ButtonsGroup
        openCreator={() => creatorOpen(true)}
        openList={() => openList(true)}
      />
      <Dialog
        transitionDuration={500}
        open={isCreatorOpen}
        fullScreen
        TransitionComponent={Transition}
      >
        <Creator close={closeCreator} state={stateRef.current} />
      </Dialog>
      <Dialog
        transitionDuration={500}
        open={isListOpen}
        fullScreen
        TransitionComponent={Transition}
      >
        <ListOfPredefined close={closeList} />
      </Dialog>
    </>
  );
};
