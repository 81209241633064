import { CardState, PreStartContainer } from './CardComponents';
import { Queue_placeType } from '../../../back/src/three_live/queue/queue_place.type';
import { Box, Button, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useConnection } from '../providers/useConnection';
import { useLocation, useNavigate } from 'react-router-dom';
import interactImg from '../media/interact.png';
export const PreStartCard: FC<{
  setDelay: (delay: Queue_placeType) => void;
}> = ({ setDelay }) => {
  const { addAnimation } = useConnection();
  const navigate = useNavigate();
  const {
    state: { animation },
  } = useLocation();
  const run = useCallback(async () => {
    try {
      const delay = await addAnimation(animation);
      setDelay(delay);
    } catch (e) {
      navigate('/');
    }
  }, [animation]);
  return (
    <PreStartContainer>
      <Box>
        <Typography
          textTransform={'uppercase'}
          color={'#584D48'}
          fontSize={'2rem'}
          textAlign={'center'}
          fontWeight={700}
          letterSpacing={'0.5px'}
        >
          INTERACT WITH YOUR TREE
        </Typography>
      </Box>
      <Box mt={5.625}>
        <Typography
          textAlign={'center'}
          variant={'h4'}
          color={'primaryColor.main'}
        >
          Move around and raise <br /> your arms to bring your <br /> creation
          to life
        </Typography>
      </Box>
      <Box mt={5.625} textAlign={'center'}>
        <img src={interactImg} alt="interacting" />
      </Box>
      <Box mt={3.5} textAlign={'center'}>
        <Button onClick={run} variant={'outlined'}>
          START
        </Button>
      </Box>
      <Box mt={3.5} textAlign={'center'}>
        <Button
          onClick={() =>
            navigate('/animations', { state: { animation, step: 2 } })
          }
          variant={'text'}
          fullWidth
        >
          Back
        </Button>
      </Box>
    </PreStartContainer>
  );
};
