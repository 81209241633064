import { FC } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

export const BlurrySelector: FC<{
  blurry: boolean;
  setBlurry: (blur: boolean) => void;
}> = ({ blurry, setBlurry }) => {
  return (
    <FormGroup
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Typography
          color={'primaryColor.main'}
          fontWeight={200}
          variant={'subtitle2'}
          pr={3.875}
        >
          Blurry
        </Typography>
        <Switch
          checked={blurry}
          onChange={(event, checked) => setBlurry(checked)}
        />
      </Stack>
      <Stack mt={'25px'} direction={'row'} alignItems={'center'}>
        <Typography
          color={'primaryColor.main'}
          fontWeight={200}
          variant={'subtitle2'}
          pr={3.875}
        >
          Sharp
        </Typography>
        <Switch
          checked={!blurry}
          onChange={(event, checked) => setBlurry(!checked)}
        />
      </Stack>
    </FormGroup>
  );
};
