import React, {
  createContext,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import socket_io, { Socket } from 'socket.io-client';
import { Box, Button, Typography } from '@mui/material';
import { MessageDialog } from '../components/MassageDialog';

type ConnectionContext = {
  isConnected: boolean | null;
  socket: Socket | null;
};

export const SocketIO = createContext<ConnectionContext>({
  isConnected: null,
  socket: null,
});

enum Messages {
  appOff = 'appOff',
  techWork = 'techWork',
}

const Connection: FC<{ children: ReactElement }> = ({ children }) => {
  const socket = useRef<Socket | null>(null);
  const [isConnected, setConnection] = useState<boolean | null>(null);
  const [message, setMessage] = useState<Messages | null>(null);
  useEffect(() => {
    if (socket.current) return;
    socket.current = socket_io(
      process.env.NODE_ENV === 'production'
        ? '/three_of_life'
        : 'https://treeoflife.expolightcontrols.net/three_of_life',
      {
        transports: ['websocket'],
      },
    );

    socket.current?.on('connect', () => {
      setConnection(true);
      setMessage(null);
      console.log('%c Success connect!', 'color:green');
    });

    socket.current?.on('disconnect', () => {
      console.log('%c Connection lost!', 'color:red');
      setConnection(false);
      setMessage(Messages.techWork);
    });

    socket.current?.on('connect_error', () => {
      setConnection(false);
      setMessage(Messages.techWork);
    });
    socket.current?.on('queueReset', () => {
      window.location.reload();
    });
    socket.current?.on('sysm', (messageType: Messages) => {
      setMessage(messageType);
    });
  }, []);
  return (
    <SocketIO.Provider value={{ isConnected, socket: socket.current }}>
      {children}
      <MessageDialog open={!!message}>
        <Box mt={'calc(var(--vh, 1vh) * 24.7)'}>
          <Typography
            variant={'h4'}
            align={'center'}
            textTransform={'uppercase'}
          >
            {message === Messages.appOff && (
              <>
                Application is not available. <br /> Please try again later
              </>
            )}
            {message === Messages.techWork && (
              <>
                Application is not available <br /> due to technical work
              </>
            )}
          </Typography>
        </Box>

        <Box mt={'calc(var(--vh, 1vh) * 36.9)'} textAlign={'center'}>
          <Button variant={'outlined'} onClick={() => window.location.reload()}>
            close
          </Button>
        </Box>
      </MessageDialog>
    </SocketIO.Provider>
  );
};

export default Connection;
