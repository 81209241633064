import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import infoImg from '../media/cardInfo.png';

export const InfoCard: FC<{ handleClose: () => void }> = ({ handleClose }) => (
  <>
    <Box textAlign={'center'} mb={4.75}>
      <img src={infoImg} alt="Card info" />
    </Box>
    <Box mb={6.125}>
      <Typography variant={'body2'} color={'#464646'}>
        Our tree symbolizes the unity of the natural and super-technological,
        the physical and digital, and the human and virtual worlds, which us as
        a species are the creators of.
      </Typography>
    </Box>
    <Box textAlign={'center'}>
      <Button variant={'text'} onClick={handleClose}>
        Close
      </Button>
    </Box>
  </>
);
