import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import interIcon from '../media/interaction.svg';
import {
  CardContainer,
  CardState,
  Container,
  countDownFn,
  Filter,
  Image,
  ImageContainer,
  TextContainer,
} from './CardComponents';
import { useLocation } from 'react-router-dom';

export const InteractionCard: FC<{
  setCardState: (state: CardState) => void;
  duration: number;
}> = ({ setCardState, duration: _duration }) => {
  const [duration, setDuration] = useState<number>(_duration);
  useEffect(() => {
    if (duration === 0) {
      setCardState(CardState.FINISH);
      return;
    }

    const timer = setTimeout(setDuration, 1000, countDownFn(1000));
    return () => clearTimeout(timer);
  }, [duration]);
  return (
    <Container>
      <Image
        style={{
          borderColor: '#fff',
          backgroundColor: '#CBB49E',
        }}
      >
        <Typography color={'white'} fontSize={'7rem'} variant={'h1'}>
          {Math.floor(duration / 1000)}
        </Typography>
      </Image>
      <TextContainer>
        <Typography
          pb={'15px'}
          textTransform={'uppercase'}
          fontWeight={800}
          textAlign={'center'}
          fontSize={'3.5rem'}
          variant={'h4'}
          color={'white'}
        >
          HERE WE GO!
        </Typography>
        <Typography
          pb={'26px'}
          fontWeight={500}
          textAlign={'center'}
          fontSize={'2rem'}
          variant={'h4'}
          color={'white'}
        >
          You have 37 seconds to interact with your 'Tree of Life' - breath in,
          move and enjoy.
        </Typography>
        <img src={interIcon} alt={'Interaction'} />
      </TextContainer>
      <CardContainer sx={{ backgroundColor: '#CBB49E' }}>
        <ImageContainer sx={{ backgroundColor: '#CBB49E' }} />
        <Filter />
      </CardContainer>
    </Container>
  );
};
