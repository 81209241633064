import { FC } from 'react';
import { CustomCard } from '../components/CustomCard';
import { Logo } from '../components/Logo';
import { InfoButton } from '../components/InfoButton';
import { Stack } from '@mui/material';

export const RunPage: FC = () => {
  return (
    <>
      <Stack
        mt={'calc(var(--vh, 1vh) * 5.285)'}
        justifyContent={'space-between'}
        direction={'row'}
        alignItems={'center'}
      >
        <Logo />
        <InfoButton visibility={'hidden'} />
      </Stack>

      <CustomCard />
    </>
  );
};
