import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { MemoryRouter } from 'react-router-dom';
import Connection from './providers/Connection';
import theme from './theme';
import FullScreenProvider from './providers/FullScreenProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const maxHeight = 852;
const minHeight = 548;
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

if (window.innerHeight <= maxHeight && window.innerHeight >= minHeight) {
  document.documentElement.style.setProperty(
    'font-size',
    `${(window.innerHeight / maxHeight) * 11}px`,
  );
}
window.addEventListener(
  'resize',
  () => {
    const vh = window.screen.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (window.innerHeight <= maxHeight && window.innerHeight >= minHeight) {
      document.documentElement.style.setProperty(
        'font-size',
        `${(window.innerHeight / maxHeight) * 10}px`,
      );
    }
  },
  { passive: true },
);

window.addEventListener(
  'fullscreenchange',
  () => {
    const vh = window.screen.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (window.innerHeight <= maxHeight && window.innerHeight >= minHeight) {
      document.documentElement.style.setProperty(
        'font-size',
        `${(window.innerHeight / maxHeight) * 10}px`,
      );
    }
  },
  { passive: true },
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MemoryRouter>
          <FullScreenProvider>
            <Connection>
              <App />
            </Connection>
          </FullScreenProvider>
        </MemoryRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
