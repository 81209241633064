import { FC, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { LargeButton } from '../pages/AnimationCreatorPage';
import {
  CardContainer,
  Container,
  Filter,
  Image,
  ImageContainer,
  TextContainer,
} from './CardComponents';

export const FinishedCard: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { current: _state } = useRef(state);
  if (_state?.predefined) {
    return (
      <Container style={{ marginTop: '-17%' }}>
        <TextContainer
          style={{
            top: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box textAlign={'center'}>
            <LargeButton
              onClick={() => navigate('/animations', { state: null })}
              variant={'outlined'}
              fullWidth
            >
              Create new tree
            </LargeButton>
          </Box>
        </TextContainer>
        <CardContainer sx={{ backgroundColor: '#f7f7f6', opacity: 0.5 }}>
          <Filter />
        </CardContainer>
      </Container>
    );
  }
  return (
    <Container>
      <Image>
        <Typography color={'#636363'} fontSize={'3rem'} variant={'h1'}>
          Time is up
        </Typography>
      </Image>
      <TextContainer
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box textAlign={'center'}>
          <LargeButton
            onClick={() => navigate('/animations', { state: null })}
            variant={'outlined'}
            fullWidth
          >
            Create new tree
          </LargeButton>
        </Box>
      </TextContainer>
      <CardContainer sx={{ backgroundColor: '#f7f7f6' }}>
        <ImageContainer sx={{ backgroundColor: '#f7f7f6' }} />
        <Filter />
      </CardContainer>
    </Container>
  );
};
