import { Queue_placeType } from '../../../back/src/three_live/queue/queue_place.type';
import { useContext, useEffect } from 'react';
import { SocketIO } from './Connection';
import { TaskDTO } from '../../../back/src/three_live/queue/types/three_of_life_shared';
type ConnectionHook = {
  isConnected: boolean;
  addAnimation: (animation: TaskDTO) => Promise<Queue_placeType>;
  sendGeolocation: (lat: number, long: number) => Promise<2 | null>;
};

export const useConnection = (
  callBack?: ((delay: number) => void) | undefined,
): ConnectionHook => {
  const { socket } = useContext(SocketIO);
  const addAnimation = async (animation: TaskDTO) => {
    if (!socket) return null;
    return await socket.emitWithAck('add', animation);
  };

  const sendGeolocation = async (
    lat: number,
    long: number,
  ): Promise<2 | null> => {
    if (!socket) return null;
    return await socket.emitWithAck('geo', { lat, long });
  };

  useEffect(() => {
    if (socket && callBack) {
      socket.on('queue_change', callBack);
      return () => {
        socket.off('queue_change', callBack);
      };
    }
  }, [socket]);

  return {
    isConnected: !!socket && socket.connected,
    addAnimation,
    sendGeolocation,
  };
};
