import { FC, memo, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import colorImg from '../media/three/masks/App_Back.jpg';
import WHIRLWIND from '../media/three/masks/map_4_twirl.mp4';
import SHINE from '../media/three/masks/map_2_shine.mp4';
import WAVE from '../media/three/masks/map_3_wave.mp4';
import GEO from '../media/three/masks/map_1_geo.mp4';
import { Effects } from '../types/three_of_life_shared';

const Container = styled('div')`
  height: calc(var(--vh, 1vh) * 36.7);
  position: relative;
  margin-top: calc(var(--vh, 1vh) * 5.2);
  & > canvas {
    background-color: transparent;
  }
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & video {
    opacity: 0;
    pointer-events: none;
  }
`;
const size = [285, 313];
const EffectsList = {
  [Effects.WHIRLWIND]: WHIRLWIND,
  [Effects.WAVE]: WAVE,
  [Effects.SHINE]: SHINE,
  [Effects.GEOMETRY]: GEO,
};
export const Preview: FC<{
  color: { r: number; g: number; b: number; a: number };
  effect: Effects;
  speed: number;
  blur: boolean;
}> = memo(
  ({ color, effect, speed, blur }) => {
    const [{ width, height }, setSize] = useState({
      width: window.innerHeight * 0.367 * 0.91,
      height: window.innerHeight * 0.367,
    });
    const effectCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const backgroundCanvas = useRef<HTMLCanvasElement | null>(null);
    const { r, g, b } = color;
    useEffect(() => {
      const canvasResize = () => {
        const height = window.innerHeight * 0.367;
        const width = height * 0.91;
        if (width >= window.innerWidth - 50) return;
        setSize({
          width,
          height,
        });
      };
      window.addEventListener('resize', canvasResize, { passive: true });
      return () => window.removeEventListener('resize', canvasResize);
    }, []);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
      const { current: videoElement } = videoRef;
      if (!videoElement) return;
      videoElement.playbackRate = speed / 100;
    }, [speed, videoRef.current]);

    useEffect(() => {
      const backgroundImgApi = backgroundCanvas.current;
      if (!backgroundImgApi) return;
      const ctx = backgroundImgApi.getContext('2d');
      if (!ctx) return;
      const img = new Image(backgroundImgApi.width, backgroundImgApi.height);
      img.onload = () => {
        ctx.drawImage(
          img,
          0,
          0,
          backgroundImgApi.width,
          backgroundImgApi.height,
        );
      };
      img.src = colorImg;
    }, [backgroundCanvas.current, width, height]);

    useEffect(() => {
      const { current: videoElement } = videoRef;
      const effectCanvasApi = effectCanvasRef.current;
      if (!effectCanvasApi) return;
      const effectCtx = effectCanvasApi.getContext('2d', {
        colorSpace: 'srgb',
        willReadFrequently: true,
      });
      if (!effectCtx || !videoElement) return;
      let animReqID: number;
      const playVideo = () => {
        effectCtx.drawImage(
          videoElement,
          0,
          0,
          effectCanvasApi.width,
          effectCanvasApi.height,
        );
        const imageData = effectCtx.getImageData(
          0,
          0,
          effectCanvasApi.width,
          effectCanvasApi.height,
        );
        const { data } = imageData;
        let frameLength = data.length - 1;
        while (frameLength > 0) {
          if (
            data[frameLength - 1] +
              data[frameLength - 2] +
              data[frameLength - 3] <
            500
          ) {
            data[frameLength] = 0; // alpha
          }
          if (
            data[frameLength - 1] +
              data[frameLength - 2] +
              data[frameLength - 3] >
            500
          ) {
            data[frameLength - 3] = r;
            data[frameLength - 2] = g;
            data[frameLength - 1] = b;
            data[frameLength] = 255 * 0.8;
          }

          frameLength = frameLength - 4;
        }
        effectCtx.putImageData(imageData, 0, 0);
        animReqID = requestAnimationFrame(playVideo);
      };
      animReqID = requestAnimationFrame(playVideo);
      return () => {
        effectCtx.clearRect(
          0,
          0,
          effectCanvasApi.width,
          effectCanvasApi.height,
        );
        cancelAnimationFrame(animReqID);
      };
    }, [videoRef.current, r, g, b]);

    return (
      <Container>
        <canvas ref={backgroundCanvas} width={width} height={height} />
        <canvas
          style={{
            filter: `blur(${blur ? 6 : 0}px)`,
          }}
          ref={effectCanvasRef}
          width={width}
          height={height}
        />
        <video
          ref={videoRef}
          src={EffectsList[effect]}
          autoPlay
          loop
          muted
          playsInline
          preload={'auto'}
          crossOrigin={'anonymous'}
        />
      </Container>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.color === nextProps.color && prevProps.speed === nextProps.speed
    );
  },
);
