import { FC, useEffect, useState } from 'react';
import { CardState } from './CardComponents';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { WaitingCard } from './WaitingCard';
import { InteractionCard } from './InteractionCard';
import { FinishedCard } from './FinishedCard';
import { PreStartCard } from './PreStartCard';
import { Queue_placeType } from '../../../back/src/three_live/queue/queue_place.type';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

const cards: Variants = {
  fadeIn: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
      ease: 'easeInOut',
    },
    y: 0,
  },
  fadeOut: {
    opacity: 0,
    y: -100,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

export const CustomCard: FC = () => {
  const [cardState, setCardState] = useState<CardState>(CardState.PRESTART);
  const { state } = useLocation();
  const [anim, setAnim] = useState<Queue_placeType>(state);
  useEffect(() => {
    if (anim.waiting === null) return;
    if (anim.waiting > 0) {
      setCardState(CardState.WAITING);
      return;
    }
    if (anim?.predefined) {
      setCardState(CardState.FINISH);
      return;
    }
    if (anim.waiting === 0) {
      setCardState(CardState.INTERACTION);
    }
  }, [anim.waiting]);
  return (
    <AnimatePresence>
      {cardState === CardState.PRESTART && (
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <motion.div
            key={'preStart'}
            initial={'fadeIn'}
            animate={'visible'}
            exit={'fadeOut'}
            variants={cards}
          >
            <PreStartCard setDelay={setAnim} />
          </motion.div>
        </Box>
      )}
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-37%)',
        }}
      >
        {cardState === CardState.WAITING && (
          <motion.div
            key={'wait'}
            initial={'fadeIn'}
            animate={'visible'}
            exit={'fadeOut'}
            variants={cards}
          >
            <WaitingCard
              waiting={anim.waiting as number}
              setCardState={setCardState}
            />
          </motion.div>
        )}

        {cardState === CardState.INTERACTION && (
          <motion.div
            key={'interact'}
            initial={'fadeIn'}
            animate={'visible'}
            exit={'fadeOut'}
            variants={cards}
          >
            <InteractionCard
              duration={anim.duration as number}
              setCardState={setCardState}
            />
          </motion.div>
        )}
        {cardState === CardState.FINISH && (
          <motion.div
            key={'finish'}
            initial={'fadeIn'}
            animate={'visible'}
            exit={'fadeOut'}
            variants={cards}
          >
            <FinishedCard />
          </motion.div>
        )}
      </Box>
    </AnimatePresence>
  );
};
