import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import {
  CardContainer,
  CardState,
  Container,
  countDownFn,
  Filter,
  Image,
  ImageContainer,
  TextContainer,
} from './CardComponents';
import { useConnection } from '../providers/useConnection';
import { useLocation } from 'react-router-dom';

export const WaitingCard: FC<{
  setCardState: (state: CardState) => void;
  waiting: number;
}> = ({ setCardState, waiting }) => {
  const [delay, setDelay] = useState<number>(waiting);
  const { state = {} } = useLocation();

  useConnection((delay) => {
    setDelay(delay);
  });
  useEffect(() => {
    if (delay === null) return;
    if (delay === 0) {
      return setCardState(
        state?.predefined ? CardState.FINISH : CardState.INTERACTION,
      );
    }
    const time = delay % 1000 || 1000;
    const timer = setTimeout(setDelay, time, countDownFn(time));
    return () => clearTimeout(timer);
  }, [delay]);
  return (
    <Container>
      <Image>
        <Typography color={'#636363'} fontSize={'7rem'} variant={'h1'}>
          {Math.floor(delay / 1000)}
        </Typography>
      </Image>
      <TextContainer>
        <Typography
          pt={'60px'}
          textAlign={'center'}
          variant={'h4'}
          color={'primaryColor.main'}
        >
          Please wait... <br />
          Your unique 'Tree of Life" <br />
          is being generated...
        </Typography>
      </TextContainer>
      <CardContainer
        sx={{
          backgroundColor: '#f7f7f6',
        }}
      >
        <ImageContainer
          sx={{
            backgroundColor: '#f7f7f6',
          }}
        />
        <Filter />
      </CardContainer>
    </Container>
  );
};
