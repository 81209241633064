import { createTheme } from '@mui/material';
import dialogBG from '../media/dialogBG.png';
import { color } from '@uiw/color-convert';
declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      mainText: {
        main: string;
      };
      primaryColor: {
        main: string;
      };
      startColor: {
        main: string;
      };
      backColor: {
        main: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    mainText: {
      main: string;
    };
    primaryColor: {
      main: string;
    };
    startColor: {
      main: string;
    };
    backColor: {
      main: string;
    };
  }
}

export default createTheme({
  palette: {
    mainText: {
      main: '#464646',
    },
    primaryColor: {
      main: '#584D48',
    },
    startColor: {
      main: '',
    },
    backColor: {
      main: '#E0D4CB',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontSize: '28px',
      fontWeight: 900,
      lineHeight: '28px',
    },
    h2: {
      fontFamily: "'Roboto Flex', sans-serif",
      fontWeight: 500,
      fontSize: '4.8rem',
    },
    h4: {
      fontFamily: "'Roboto Flex', sans-serif",
      fontSize: '2rem',
      fontWeight: 400,
    },
    h5: {
      fontFamily: "'Roboto Flex', sans-serif",
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontFamily: "'Roboto Flex', sans-serif",
      fontSize: '1.2rem',
      fontWeight: 300,
      lineHeight: '14.06px',
    },
    body1: {
      fontFamily: "'Roboto Flex', sans-serif",
      fontSize: '1.4rem',
      lineHeight: '1.14em',
      fontWeight: 400,
    },
    body2: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontSize: '1.6rem',
      lineHeight: '18.75px',
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          whiteSpace: 'nowrap',
          background: '#E0D4CB',
          border: '1px solid #584D48',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '33px',
          fontFamily: "'Roboto Flex', sans-serif",
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: '1.1875em',
          color: '#584D48',
          padding: '1.2rem 41px',
          '&:hover': {
            background: '#E0D4CB',
            border: '1px solid #584D48',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            color: '#584D48',
          },
        },
        text: {
          color: '#584D48',
          textDecoration: 'underline',
          fontFamily: 'Roboto Flex',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: '19px',
          letterSpacing: '0.065em',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: `url(${dialogBG})`,
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          padding: '0 25px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 72,
          height: 19,
          overflow: 'unset',
          padding: 0,
        },
        thumb: {
          width: 25,
          height: 25,
          boxShadow: '10px 4px 14px rgba(0, 0, 0, 0.25)',
          padding: 0,
        },
        track: {
          backgroundColor: '#E0E0E0',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          opacity: 1,
        },

        switchBase: {
          transform: 'translate(-12px,-12px)',
          '&.Mui-checked': {
            color: '#584D48',
            transform: 'translate(40px, -12px)',
            '& + .MuiSwitch-track': {
              backgroundColor: '#7A6D67',
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          background: '#E0E0E0',
          opacity: 1,
        },
        track: {
          background: '#584D48',
          borderColor: '#584D48',
        },
        thumb: {
          background: '#fff',
        },
      },
    },
  },
});
