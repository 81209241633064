import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Logo } from '../components/Logo';
import { InfoButton } from '../components/InfoButton';
import { useNavigate } from 'react-router-dom';
import { InfoCard } from '../components/InfoCard';
import { GeneralLogo } from '../components/GeneralLogo';

export const MainPage: FC = () => {
  const navigate = useNavigate();
  const [isOpen, open] = useState(false);
  const closeHandler = () => open(false);
  const openHandler = () => open(true);
  return (
    <>
      <Dialog
        onClose={closeHandler}
        fullWidth
        maxWidth={'sm'}
        open={isOpen}
        PaperProps={{
          sx: {
            backgroundColor: '#F7F7F6',
            borderRadius: '21px',
            padding: '15px 32px 22px 32px',
          },
        }}
      >
        <InfoCard handleClose={closeHandler} />
      </Dialog>
      <Stack
        mt={'calc(var(--vh, 1vh) * 5.285)'}
        justifyContent={'space-between'}
        direction={'row'}
        alignItems={'center'}
      >
        <Logo />
        <InfoButton onClick={openHandler} />
      </Stack>

      <Box>
        <Typography
          variant={'h1'}
          color={'white'}
          textTransform={'uppercase'}
          style={{ textShadow: '3px 4px 4px rgba(0, 0, 0, 0.5)' }}
        >
          The Tree of life
        </Typography>
        <Typography
          mt={1}
          variant={'body1'}
          color={'white'}
          style={{ textShadow: '3px 4px 4px rgba(0, 0, 0, 0.5)' }}
        >
          Create your own unique
          <br />
          interactive installation
        </Typography>
      </Box>
      <Box textAlign={'center'} mt={'calc(var(--vh, 1vh) * 55.1)'}>
        <Button variant={'outlined'} onClick={() => navigate('/animations')}>
          Start
        </Button>
      </Box>
    </>
  );
};
