import React, { FC } from 'react';
import { Dialog, Stack } from '@mui/material';
import { Logo } from './Logo';
import { InfoButton } from './InfoButton';

export const MessageDialog: FC<{
  children: JSX.Element[] | JSX.Element | string;
  open: boolean;
  onClose?: () => void;
}> = ({ children, open = false }) => (
  <Dialog
    fullScreen
    sx={{
      '& .MuiModal-backdrop': {
        backgroundColor: 'transparent',
        backdropFilter: 'blur(10px)',
      },
      '& .MuiDialog-paper': {
        backgroundColor: 'transparent',
        backgroundImage: 'unset',
        boxShadow: 'unset',
      },
    }}
    open={open}
  >
    <Stack
      mt={'calc(var(--vh, 1vh) * 5.285)'}
      justifyContent={'space-between'}
      direction={'row'}
      alignItems={'center'}
    >
      <Logo />
      <InfoButton visibility={'hidden'} />
    </Stack>
    {children}
  </Dialog>
);
