import { FC } from 'react';
import { Button, styled, Typography } from '@mui/material';

const VideoButton = styled(Button)`
  background-color: #fff;
  flex-direction: column;
  border-radius: 15px;
  padding: 8px 8px 12px 8px;
  align-items: stretch;
  justify-content: flex-start;
  &:hover {
    background-color: #fff;
  }
  & video {
    border-radius: inherit;
    width: 100%;
    height: 137px;
    object-fit: cover;
  }
`;

export const PredefinedButton: FC<{
  name?: string;
  description: string;
  preview: string;
  onClick: (name: string) => () => Promise<void>;
}> = ({ name = 'Hello', description, preview, onClick }) => {
  return (
    <VideoButton variant={'contained'} fullWidth onClick={onClick(name)}>
      <video
        src={preview}
        autoPlay
        loop
        muted
        playsInline
        preload={'auto'}
        crossOrigin={'anonymous'}
      />
      <Typography
        mb={1}
        mt={1}
        textAlign={'center'}
        textTransform={'uppercase'}
        color={'primaryColor.main'}
      >
        {name}
      </Typography>
      <Typography
        textTransform={'none'}
        variant={'subtitle2'}
        textAlign={'left'}
        color={'primaryColor.main'}
      >
        {description}
      </Typography>
    </VideoButton>
  );
};
