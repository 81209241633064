import logo from '../media/KLDS.png';
import styled from '@emotion/styled';

const Logo = styled.img`
  max-width: 300px;
  min-width: 100px;
  width: 25.5vw;
`;

const Container = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GeneralLogo = () => (
  <Container>
    <Logo src={logo} />
  </Container>
);
