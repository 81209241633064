import logo from '../media/x37logo.svg';
export const Logo = () => {
  return (
    <img
      src={logo}
      style={{ height: 'calc(var(--vh, 1vh) * 3.6)', maxHeight: 40 }}
      alt={'Gallery 37'}
    />
  );
};
