import { RouteObject } from 'react-router-dom';
import { ViewContainer } from '../pages/ViewContainer';
import { MainPage } from '../pages/MainPage';
import { AnimationCreatorPage } from '../pages/AnimationCreatorPage';
import { RunPage } from '../pages/RunPage';

export const router: RouteObject[] = [
  {
    path: '/',
    element: (
      <ViewContainer keyAnim={'/'}>
        <MainPage />
      </ViewContainer>
    ),
  },
  {
    path: 'animations',
    element: (
      <ViewContainer keyAnim={'/animations'}>
        <AnimationCreatorPage />
      </ViewContainer>
    ),
  },
  {
    path: 'run',
    element: (
      <ViewContainer keyAnim={'/run'}>
        <RunPage />
      </ViewContainer>
    ),
  },
];
